import { DropDownList } from "@progress/kendo-react-dropdowns"; // Import Kendo dropdown
import { Button } from "@progress/kendo-react-buttons";
import { useMemo, useState } from "react";
import { NewMoveStatus } from "../../new/helpers";
import { MoveGrid } from "../grids/MoveGrid";

// const useExtraColumns = (handleAction: (item: ActionItem) => undefined) => {
// 	return useMemo(() => {
// 		const _columns: TypedGridColumnProps<MoveGrid>[] = [
// 			{
// 				title: "Actions",
// 				cell: ({ dataItem }) => {

// 					const items: StatusItem[] = [];
// 					if (dataItem.actions.allowAudit) {
// 						items.push({
// 							text: "Edit",
// 							action: "allowAudit",
// 							data: dataItem,
// 						});
// 					}
// 					if (dataItem.actions.allowReassignHaulier) {
// 						items.push({
// 							text: "Reassign Haulier",
// 							action: "allowReassignHaulier",
// 							data: dataItem,
// 						});
// 					}
// 					if (dataItem.actions.allowReassignWarehouse) {
// 						items.push({
// 							text: "Reassign Warehouse",
// 							action: "allowReassignWarehouse",
// 							data: dataItem,
// 						});
// 					}
// 					if (dataItem.actions.allowChangeStatus) {
// 						items.push({
// 							text: "Change Status",
// 							action: "allowChangeStatus",
// 							data: dataItem,
// 						});
// 					}
// 					if (dataItem.actions.allowRemove) {
// 						items.push({
// 							text: "Remove",
// 							action: "allowRemove",
// 							data: dataItem,
// 						});
// 					}
// 					return (
// 						<td>
// 							<Button
// 								size="small"
// 								icon="eye"
// 								title="View Details"
// 								onClick={() =>
// 									handleAction({ action: "allowViewDetails", data: dataItem })
// 								}
// 							/>
// 							<TypedDropDownButton
// 								size="small"
// 								icon="more-vertical"
// 								title="More Actions"
// 								items={items}
// 								itemRender={StatusItemRender}
// 								onItemClick={(x) => handleAction(x.item)}
// 								popupSettings={{ animate: false }}
// 							/>
// 						</td>
// 					);
// 				},
// 				field: "actions",
// 				width: "70px",
// 			},
// 		];
// 		return _columns;
// 	}, [handleAction]);
// };

const useFetchData = () => {
	const _move = useMemo(
		() => [
			{
				id: 39390,
				uniqueId: "00257245",
				purchaseOrderNumber: "TBC",
				status: 1,
				customerName: "John McCall",
				startLocationName: "PO15EQ",
				endLocationName: "PO38IS",
				startDate: "2025-03-07T08:00:00",
				endDate: "2025-03-07T16:00:00",
				price: 100,
				cost: 20,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: null,
				assignedTo: "8baafd73-14a8-4975-bc64-e969583e679e",
				assignedToName: "Celerum Admin",
				haulierId: 1,
				haulierName: "Haulier 1",
				fromAddress: "PO15EQ",
				toAddress: "PO33US",
				distance: 100,
				statusName: "Assigned",
				typeName: "Collection",
				assignedHaulierName: "Haulier 1",
				actions: {
					allowViewDetails: true,
					allowEdit: true,
					allowAudit: true,
					allowChangeStatus: true,
					allowRemove: true,
					allowReassignHaulier: true,
					allowReassignWarehouse: true,
				},
				driverName: null,
				isNew: false,
			},

			{
				id: 39396,
				uniqueId: "00257251",
				purchaseOrderNumber: "PO-20250312-006",
				status: 1,
				customerName: "Frank Jones",
				startLocationName: "SE11LL",
				endLocationName: "CR01AA",
				startDate: "2025-03-12T14:00:00",
				endDate: "2025-03-12T22:00:00",
				price: 140,
				cost: 28,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: null,
				assignedTo: "e2ce1399-7af0-a52a-01b9-34b4a0839ce3",
				assignedToName: "Michael Clark",
				haulierId: 3,
				haulierName: "Haulier 3",
				fromAddress: "SE11LL",
				toAddress: "CR01AA",
				distance: 140,
				statusName: "Assigned",
				typeName: "Collection",
				assignedHaulierName: "Haulier 3",
				actions: {
					allowViewDetails: true,
					allowEdit: true,
					allowAudit: true,
					allowChangeStatus: true,
					allowRemove: true,
					allowReassignHaulier: true,
					allowReassignWarehouse: true,
				},
				driverName: "Driver 4",
				isNew: false,
			},
			{
				id: 39397,
				uniqueId: "00257252",
				purchaseOrderNumber: "PO-20250313-007",
				status: 1,
				customerName: "Grace Williams",
				startLocationName: "BR11LL",
				endLocationName: "SE11LL",
				startDate: "2025-03-13T15:00:00",
				endDate: "2025-03-13T23:00:00",
				price: 200,
				cost: 40,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: "Subcontractor A",
				assignedTo: "f3cf14a0-8be1-b63b-12c0-45c5a1849df4",
				assignedToName: "Emily Brown",
				haulierId: 1,
				haulierName: "Haulier 1",
				fromAddress: "BR11LL",
				toAddress: "SE11LL",
				distance: 200,
				statusName: "Assigned",
				typeName: "Collection",
				assignedHaulierName: "Haulier 1",
				actions: {
					allowViewDetails: true,
					allowEdit: false,
					allowAudit: true,
					allowChangeStatus: false,
					allowRemove: false,
					allowReassignHaulier: false,
					allowReassignWarehouse: false,
				},
				driverName: "Driver 5",
				isNew: false,
			},
			{
				id: 39398,
				uniqueId: "00257253",
				purchaseOrderNumber: "PO-20250314-008",
				status: 1,
				customerName: "Henry Davis",
				startLocationName: "WC1N3AX",
				endLocationName: "WC1N3AX",
				startDate: "2025-03-14T16:00:00",
				endDate: "2025-03-21T16:00:00",
				price: 70,
				cost: 14,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: null,
				assignedTo: "g4df15b1-9cf2-c74c-23d1-56d6a2859e55",
				assignedToName: "Oliver Green",
				haulierId: 2,
				haulierName: "Haulier 2",
				fromAddress: "WC1N3AX",
				toAddress: "WC1N3AX",
				distance: 0,
				statusName: "Assigned",
				typeName: "Return",
				assignedHaulierName: "Haulier 2",
				actions: {
					allowViewDetails: true,
					allowEdit: true,
					allowAudit: true,
					allowChangeStatus: true,
					allowRemove: true,
					allowReassignHaulier: true,
					allowReassignWarehouse: true,
				},
				driverName: null,
				isNew: true,
			},
			{
				id: 39399,
				uniqueId: "00257254",
				purchaseOrderNumber: "PO-20250315-009",
				status: 1,
				customerName: "Isabella Brown",
				startLocationName: "N11AA",
				endLocationName: "EC1V4PB",
				startDate: "2025-03-15T17:00:00",
				endDate: "2025-03-15T23:00:00",
				price: 160,
				cost: 32,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: "Subcontractor B",
				assignedTo: "h5ef16c2-a0d3-d85d-34e2-67d7a3869f66",
				assignedToName: "Charlie White",
				haulierId: 3,
				haulierName: "Haulier 3",
				fromAddress: "N11AA",
				toAddress: "EC1V4PB",
				distance: 160,
				statusName: "Assigned",
				typeName: "Collection",
				assignedHaulierName: "Haulier 3",
				actions: {
					allowViewDetails: true,
					allowEdit: true,
					allowAudit: true,
					allowChangeStatus: true,
					allowRemove: true,
					allowReassignHaulier: true,
					allowReassignWarehouse: true,
				},
				driverName: "Driver 6",
				isNew: false,
			},
			{
				id: 39391,
				uniqueId: "00257246",
				purchaseOrderNumber: "PO-20250307-001",
				status: 1,
				customerName: "Alice Smith",
				startLocationName: "RG10AA",
				endLocationName: "OX11BB",
				startDate: "2025-03-07T09:00:00",
				endDate: "2025-03-07T17:00:00",
				price: 150,
				cost: 30,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: "Subcontractor X",
				assignedTo: "9cdbeef4-25b9-5086-cd75-f070694f78af",
				assignedToName: "John Doe",
				haulierId: 2,
				haulierName: "Haulier 2",
				fromAddress: "RG10AA",
				toAddress: "OX11BB",
				distance: 150,
				statusName: "Assigned",
				typeName: "Collection",
				assignedHaulierName: "Haulier 2",
				actions: {
					allowViewDetails: true,
					allowEdit: false,
					allowAudit: true,
					allowChangeStatus: false,
					allowRemove: false,
					allowReassignHaulier: false,
					allowReassignWarehouse: false,
				},
				driverName: "Driver 1",
				isNew: false,
			},
			{
				id: 39392,
				uniqueId: "00257247",
				purchaseOrderNumber: "PO-20250308-002",
				status: 2,
				customerName: "Bob Johnson",
				startLocationName: "SW12CC",
				endLocationName: "SW12CC",
				startDate: "2025-03-08T10:00:00",
				endDate: "2025-03-15T10:00:00",
				price: 50,
				cost: 10,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: null,
				assignedTo: "aefdcf55-36ca-6197-de86-01817a5089b0",
				assignedToName: "Jane Smith",
				haulierId: 3,
				haulierName: "Haulier 3",
				fromAddress: "SW12CC",
				toAddress: "SW12CC",
				distance: 0,
				statusName: "Locked",
				typeName: "Return",
				assignedHaulierName: "Haulier 3",
				actions: {
					allowViewDetails: true,
					allowEdit: true,
					allowAudit: true,
					allowChangeStatus: true,
					allowRemove: true,
					allowReassignHaulier: true,
					allowReassignWarehouse: true,
				},
				driverName: null,
				isNew: true,
			},
			{
				id: 39393,
				uniqueId: "00257248",
				purchaseOrderNumber: "PO-20250309-003",
				status: 0,
				customerName: "Charlie Brown",
				startLocationName: "GU13DD",
				endLocationName: "KT14EE",
				startDate: "2025-03-09T11:00:00",
				endDate: "2025-03-09T18:00:00",
				price: 120,
				cost: 25,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: "Subcontractor Y",
				assignedTo: "bfaed066-47db-7208-ef97-12928b619ac1",
				assignedToName: "David Lee",
				haulierId: 1,
				haulierName: "Haulier 1",
				fromAddress: "GU13DD",
				toAddress: "KT14EE",
				distance: 120,
				statusName: "Unassigned",
				typeName: "Collection",
				assignedHaulierName: "",
				actions: {
					allowViewDetails: true,
					allowEdit: true,
					allowAudit: true,
					allowChangeStatus: true,
					allowRemove: true,
					allowReassignHaulier: true,
					allowReassignWarehouse: true,
				},
				driverName: "Driver 2",
				isNew: false,
			},
			{
				id: 39394,
				uniqueId: "00257249",
				purchaseOrderNumber: "PO-20250310-004",
				status: 1,
				customerName: "Diana Green",
				startLocationName: "HP15FF",
				endLocationName: "SL16GG",
				startDate: "2025-03-10T12:00:00",
				endDate: "2025-03-10T20:00:00",
				price: 180,
				cost: 35,
				currencyId: 1,
				currencyCode: "GBP",
				subcontractorName: null,
				assignedTo: "c0bfd177-58ec-8319-f0a8-23a39c729bd2",
				assignedToName: "Sarah Jones",
				haulierId: 2,
				haulierName: "Haulier 2",
				fromAddress: "HP15FF",
				toAddress: "SL16GG",
				distance: 180,
				statusName: "Assigned",
				typeName: "Collection",
				assignedHaulierName: "Haulier 2",
				actions: {
					allowViewDetails: true,
					allowEdit: false,
					allowAudit: true,
					allowChangeStatus: false,
					allowRemove: false,
					allowReassignHaulier: false,
					allowReassignWarehouse: false,
				},
				driverName: "Driver 3",
				isNew: false,
			},
		],
		[],
	);

	const move = useMemo(
		() =>
			_move.map((item) => {
				const move: MoveGrid = {
					id: item.id,
					status: item.status,
					statusName: item.statusName,
					typeName: item.typeName,
					fromAddress: item.fromAddress,
					toAddress: item.toAddress,
					assignedHaulierName: item.assignedHaulierName,
					distance: item.distance,
					uniqueId: item.uniqueId,
					assignedHaulierId: item.haulierId,
					actions: {
						allowAudit: item.actions.allowAudit ?? false,
						allowViewDetails: item.actions.allowViewDetails ?? false,
						allowChangeStatus: item.actions.allowChangeStatus ?? false,
						allowRemove: item.actions.allowRemove ?? false,
						allowReassignHaulier: item.actions.allowReassignHaulier ?? false,
						allowReassignWarehouse:
							item.actions.allowReassignWarehouse ?? false,
					},
				};
				return move;
			}),
		[_move],
	);

	return { move };
};

export const PlanningPage = () => {
    const { move: initialMove } = useFetchData(); // Fetch the initial data

    const [move, setMove] = useState(initialMove); // State to manage move data
    const [loading] = useState(false);
    const [editingRowId, setEditingRowId] = useState<number | null>(null); // Track the row being edited
    const haulierOptions = ["Haulier 1", "Haulier 2", "Haulier 3", "Haulier 4", "Haulier 5"]; // Haulier options

    const assignedAndLockedMoves = useMemo(
        () => move.filter((item) => item.statusName === "Assigned" || item.statusName === "Locked"),
        [move]
    );

    const unassignedMoves = useMemo(
        () => move.filter((item) => item.statusName === "Unassigned"),
        [move]
    );

    function retry(): void {
        window.location.reload(); // Simple approach to refresh the page
    }

    const handleHaulierChange = (e: any, rowId: number) => {
        const updatedMove = move.map((item) =>
            item.id === rowId
                ? {
                      ...item,
                      assignedHaulierName: e.value,
                      status: 1, // Update status to "Assigned"
                      statusName: "Assigned", // Update statusName to "Assigned"
                  }
                : item
        );
        setMove(updatedMove); // Update the move state with the new haulier and status
        setEditingRowId(null); // Exit edit mode after selection
    };

    const handleRemoveHaulier = (rowId: number) => {
        const updatedMove = move.map((item) =>
            item.id === rowId
                ? { ...item, assignedHaulierName: "", status: 0, statusName: "Unassigned" }
                : item
        );
        setMove(updatedMove); // Update the move state with the new status and haulier
    };

    return (
        <>
            <title>Orders</title>
            {/* Top table: Assigned and Locked */}
            <MoveGrid
                data={assignedAndLockedMoves} // Filtered data for Assigned and Locked
                refresh={retry} // Provide the retry function for refreshing data
                extraColumns={[
                    {
                        title: "Assigned Haulier",
                        cell: ({ dataItem }) => (
                            <td>
                                {editingRowId === dataItem.id ? (
                                    <DropDownList
                                        data={haulierOptions}
                                        defaultValue={dataItem.assignedHaulierName}
                                        onChange={(e) => handleHaulierChange(e, dataItem.id)}
                                    />
                                ) : (
                                    dataItem.assignedHaulierName
                                )}
                            </td>
                        ),
                        field: "AssignedHaulierName",
                        width: "150px",
                    },
                    {
                        title: "Actions",
                        cell: ({ dataItem }) => (
                            <td>
                                <Button
                                    size="small"
                                    icon="edit"
                                    title="Reassign Haulier"
                                    onClick={() => setEditingRowId(dataItem.id)} // Enable edit mode for the row
                                />
                                <Button
                                    size="small"
                                    icon="trash"
                                    title="Remove Haulier"
                                    onClick={() => handleRemoveHaulier(dataItem.id)} // Call the remove haulier handler
                                />
                            </td>
                        ),
                        field: "actions",
                        width: "100px",
                    },
                ]}
                extraButtons={
                    <Button icon="plus" themeColor="primary">
                        Refine Tasks
                    </Button>
                }
                loading={loading}
                footer={{}}
            />

            <h3>Unassigned Orders</h3>
            {/* Bottom table: Unassigned */}
            <MoveGrid
                data={unassignedMoves} // Filtered data for Unassigned
                refresh={retry} // Provide the retry function for refreshing data
                extraColumns={[
                    {
                        title: "Assigned Haulier",
                        cell: ({ dataItem }) => (
                            <td>
                                {editingRowId === dataItem.id ? (
                                    <DropDownList
                                        data={haulierOptions}
                                        defaultValue={dataItem.assignedHaulierName}
                                        onChange={(e) => handleHaulierChange(e, dataItem.id)}
                                    />
                                ) : (
                                    dataItem.assignedHaulierName
                                )}
                            </td>
                        ),
                        field: "AssignedHaulierName",
                        width: "150px",
                    },
                    {
                        title: "Actions",
                        cell: ({ dataItem }) => (
                            <td>
                                <Button
                                    size="small"
                                    icon="edit"
                                    title="Reassign Haulier"
                                    onClick={() => setEditingRowId(dataItem.id)} // Enable edit mode for the row
                                />
                            </td>
                        ),
                        field: "actions",
                        width: "100px",
                    },
                ]}
                loading={loading}
            />
        </>
    );
};
// function handleAction(item: ActionItem): undefined {
// 	switch (item.action) {
// 		case "allowAudit":
// 			console.log("Auditing item:", item.data);
// 			break;
// 		case "allowReassignHaulier":
// 			console.log("Reassigning haulier for item:", item.data);
// 			break;
// 		case "allowReassignWarehouse":
// 			console.log("Reassigning warehouse for item:", item.data);
// 			break;
// 		case "allowChangeStatus":
// 			console.log("Changing status for item:", item.data);
// 			break;
// 		case "allowRemove":
// 			console.log("Removing item:", item.data);
// 			break;
// 		case "allowViewDetails":
// 			console.log("Viewing details for item:", item.data);
// 			break;
// 		default:
// 			console.warn("Unhandled action:", item.action);
// 	}
// 	return undefined;
// }
